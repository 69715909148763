<template>
  <!-- Multiple Column -->
  <v-col cols="12">
    <v-card>
      <v-card-title>Nouvelle Livraison</v-card-title>
      <v-card-text>
        <NewLivraisonForm></NewLivraisonForm>
      </v-card-text>
    </v-card>
  </v-col>
</template>

<script>
import NewLivraisonForm from './NewLivraisonForm.vue'

export default {
  components: {
    NewLivraisonForm,
  },
}
</script>

<style>

</style>
