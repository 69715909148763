<template>
  <v-simple-table>
    <template v-slot:default>
      <thead>
        <tr v-if="entete">
          <th
            v-for="(item, index) in leads[0]"
            :key="index"
          >
            <v-select
              v-model="matchField[index]"
              :items="fieldList"
              :item-text="'name'"
              :item-value="'value'"
              label="En tête"
              dense
              placeholder="Quel champ ?"
              hide-details
              outlined
              @change="checkVal()"
            ></v-select>
          </th>
        </tr>
        <tr>
          <th
            v-for="(item, index) in leads[0]"
            :key="index"
            class="text-uppercase text-center"
          >
            {{ item }}
          </th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="(item, index2) in leads"
          :key="index2"
        >
          <td
            v-for="(subitem, index3) in item"
            :key="index3"
            class="text-center"
          >
            {{ subitem }}
          </td>
        </tr>
      </tbody>
    </template>
  </v-simple-table>
</template>

<script>
export default {
  props: {
    leads: {
      type: Array,
      default: () => [],
    },
    entete: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      fieldList:
      [
        {
          name: 'Nom',
          value: 'Nom',
        },
        {
          name: 'Prénom',
          value: 'Prenom',
        },
        {
          name: 'Téléphone',
          value: 'Telephone',
        },
        {
          name: 'Intéret',
          value: 'Interet',
        },
        {
          name: 'Durée de travail',
          value: 'Anciennete',
        },
        {
          name: 'Type d\'emploi',
          value: 'Emploi',
        },
        {
          name: 'Adresse e-mail',
          value: 'email',
        },
        {
          name: 'Note',
          value: 'note',
        },
      ],
      matchField: [],
      leadsFielded: [],
    }
  },
  methods: {
    checkVal() {
      if (this.leads[0].length !== this.matchField.length) {
        this.$emit('enteteSetEvent', 2)
      }
    },
    leadsToSend() {
      this.leadsFielded = []
      this.leads.forEach(value => {
        if (value.length < 2) {
          return
        }
        const arrayToPush = {}
        value.forEach((info, index2) => {
          const key = this.matchField[index2]
          arrayToPush[key] = info
        })
        this.leadsFielded.push(arrayToPush)
      })
    },
  },
}
</script>

<style>

</style>
