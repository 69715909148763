<template>
  <v-form
    class="multi-col-validation"
  >
    <v-row>
      <v-col
        cols="12"
        md="6"
      >
        <v-text-field
          v-model="nom"
          label="Nom de la livraison"
          outlined
          dense
          placeholder="Nom Livraison"
          hide-details
        ></v-text-field>
      </v-col>

      <v-col
        cols="12"
        md="6"
      >
        <v-select
          v-model="partenaire"
          :items="partenairesList"
          label="Partenaire"
          dense
          placeholder="Partenaire leads"
          hide-details
          outlined
        ></v-select>
      </v-col>

      <v-col
        cols="12"
        md="6"
      >
        <v-text-field
          v-model="prix"
          label="Prix"
          outlined
          dense
          placeholder="Prix au lead"
          hide-details
        ></v-text-field>
      </v-col>

      <v-col
        cols="12"
        md="6"
      >
        <v-text-field
          v-model="note"
          label="Note"
          outlined
          dense
          placeholder="Note"
          hide-details
        ></v-text-field>
      </v-col>
      <v-col
        cols="6"
      >
        <v-file-input
          v-model="file"
          @change="fielding(file)"
        >
        </v-file-input>
      </v-col>
      <v-col
        cols="6"
      >
        <v-checkbox
          v-if="fileParsed.length > 0"
          v-model="checked"
          label="En tête manuel ?"
          hide-details
        ></v-checkbox>
      </v-col>
      <v-col cols="12">
        <v-btn
          color="primary"
          @click="process()"
        >
          Importer
        </v-btn>
        <v-btn
          outlined
          class="mx-2"
          color="success"
          @click="renderLeads()"
        >
          Valider
        </v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col
        cols="12"
      >
        <NewLivraisonFormLeadsTableVue
          ref="formLeadTable"
          :leads="fileParsed"
          :entete="checked"
        >
        </NewLivraisonFormLeadsTableVue>
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
import axios from 'axios'
import Papa from 'papaparse'

import NewLivraisonFormLeadsTableVue from './NewLivraisonFormLeadsTable.vue'

export default {
  components: {
    NewLivraisonFormLeadsTableVue,
  },
  data() {
    return {
      file: [],
      checked: false,
      postedLivraison: {},
      nom: '',
      partenaire: '',
      prix: '',
      note: '',
      partenairesList: [],
      parseConfig: {
        delimiter: '',
        newline: '',
        quoteChar: '"',
        escapeChar: '"',
        header: false,
        transformHeader: undefined,
        dynamicTyping: false,
        preview: 0,
        encoding: '',
        worker: false,
        comments: false,
        step: undefined,
        complete: undefined,
        error: undefined,
        download: false,
        downloadRequestHeaders: undefined,
        downloadRequestBody: undefined,
        skipEmptyLines: false,
        chunk: undefined,
        chunkSize: undefined,
        fastMode: undefined,
        beforeFirstChunk: undefined,
        withCredentials: undefined,
        transform: undefined,
        delimitersToGuess: [',', '\t', '|', ';', Papa.RECORD_SEP, Papa.UNIT_SEP],
      },
      fileParsed: [],
      enteteSet: true,
    }
  },
  mounted() {
    axios.get(`${process.env.VUE_APP_API_URL}/partenaires`, {}).then(
      response => {
        /* eslint no-plusplus: ["error", { "allowForLoopAfterthoughts": true }] */
        for (let i = 0; i < response.data.length; i++) {
          this.partenairesList.push(response.data[i].nom)
        }
      },
      error => {
        console.log(error)
      },
    )
  },
  methods: {
    fielding(file) {
      this.nom = file.name.replace(/\.[^/.]+$/, '')
    },
    process() {
      console.log(Papa.parse(this.file, {
        complete: results => {
          this.fileParsed = results.data
        },
      }))
    },
    renderLeads() {
      this.$refs.formLeadTable.leadsToSend()
      console.log(this.$refs.formLeadTable.leadsFielded)
      axios.post(`${process.env.VUE_APP_API_URL}/livraisons`, {
        nom: this.nom,
        partenaire: this.partenaire,
        prix: this.prix,
      }).then(
        response => {
          this.postedLivraison = response.data
          this.$refs.formLeadTable.leadsFielded.forEach(element => {
            element.livraison = this.postedLivraison.id // eslint-disable-line no-param-reassign
            console.log(element)
            axios.post(`${process.env.VUE_APP_API_URL}/leads`, element)
              .then(
                responseLead => {
                  console.log(responseLead)
                },
              )
              .catch(
                errLeads => {
                  console.error(errLeads)
                },
              )
          })
        },
      )
        .catch(
          err => {
            console.error(err)
          },
        )
    },
  },
}
</script>
